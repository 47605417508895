@import '../../../../../../styles/shared';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.smallImageWrapper {
  padding: 25px 0 20px;
}

.body {
  max-width: 740px;

  & > :last-child {
    margin-bottom: 0;
  }
}

.brandLogo {
  margin-bottom: 20px;

  @include mq($from: desktop) {
    margin-bottom: 30px;
  }
}

.preTitle {
  margin-bottom: 20px;

  @include mq($from: desktop) {
    margin-bottom: 30px;
  }
}

.valueProposition {
  margin-bottom: 15px;

  @include mq($from: desktop) {
    margin-bottom: 25px;
  }
}

.subTitle {
  margin-bottom: 45px;

  @include mq($from: desktop) {
    margin-bottom: 45px;
  }
}

.sponsorLogo {
  margin-top: 25px;

  @include mq($from: desktop) {
    margin-top: 35px;
  }
}

.keywordSearch {
  margin-top: 40px;

  @include mq($from: desktop) {
    max-width: var(--hub-hero-module-keyword-search-max-width);
    margin-top: 60px;
  }
}

.keywordSearchTitle {
  margin-bottom: 15px;
  font-family: var(--hub-hero-module-keyword-search-title-font-family);
  font-size: var(--hub-hero-module-keyword-search-title-font-size);

  @include mq($from: desktop) {
    margin-bottom: 25px;
  }
}

.browseEventsLink {
  margin-top: 30px;

  @include mq($from: desktop) {
    margin-top: 40px;
  }
}

.ctaGroup {
  margin-top: 25px;

  @include mq($from: desktop) {
    margin-top: 40px;
  }
}

.rightImage {
  display: none;

  @include mq($from: desktop) {
    display: flex;
    justify-content: flex-end;
  }
}

.overlay {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background: var(--hub-hero-module-overlay-background);
  }
}
