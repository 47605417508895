@import '../../../../../../../styles/shared';

$jigsaw: 85px;
$jigsaw-mobile: 50px;

@mixin nav-offset {
  bottom: #{$jigsaw-mobile + 40px};

  @include mq($from: desktop) {
    bottom: #{$jigsaw + 40px};
  }
}

.slider {
  @include overlay;

  :global(.slick-slider),
  :global(.slick-list),
  :global(.slick-track) {
    height: 100%;
  }

  :global(.slick-dots) {
    z-index: 1;

    @include nav-offset;
  }
}

.slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;

  @include nav-offset;
}

.navBody {
  display: flex;
  justify-content: space-between;
}

.navArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.25);
  border: 0;
  border-radius: 50%;
  z-index: 1;
  transform: translateY(18px);
  transition: all 0.2s;

  &::before {
    font-family: 'Material Icons', sans-serif;
    font-size: 22px;
    line-height: 1;
    color: #fff;
  }

  &:hover {
    background: var(--color-scheme-accent-main);
  }
}

.navArrowPrev {
  &::before {
    content: 'west';
  }
}

.navArrowNext {
  &::before {
    content: 'east';
  }
}
