@import '../../../../../../../styles/shared';

.heading {
  font-family: var(--hub-hero-module-value-proposition-font-family);
  font-weight: var(--hub-hero-module-value-proposition-font-weight);
  line-height: var(--hub-hero-module-value-proposition-line-height);
}

.normal {
  font-size: var(--hub-hero-module-value-proposition-font-size);
}

.smaller {
  font-size: var(--hub-hero-module-value-proposition-smaller-font-size);
}
