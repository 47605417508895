@import '../../../../../../../styles/shared';

.heading {
  font-size: 12px;

  @include mq($from: desktop) {
    height: 14px;
  }
}

.logo {
  margin-top: 10px;

  @include mq($from: desktop) {
    margin-top: 20px;
  }

  img {
    height: 20px;

    @include mq($from: desktop) {
      height: 30px;
    }
  }
}
