@import '../../../../../../styles/shared';

.wrapper {
  position: relative;

  input {
    width: calc(100% - 1px);
    height: 72px;
    margin: 0;
    padding: 0 92px 0 72px;
    font-size: 18px;
    appearance: none;
    color: #333;
    background: #f2f2f2;
    border: 0;
    border-radius: 36px;

    &:focus {
      outline: none;
      background: #efefef;
    }

    @include mq($from: desktop) {
      height: 80px;
      padding: 0 100px 0 80px;
      font-size: 22px;
      border-radius: 40px;
    }
  }
}

.button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 36px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.2s;

  @include mq($from: desktop) {
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
}

.startButton {
  @extend .button;
  top: 0;
  left: 0;
  color: #6a6f71;
}

.endButton {
  @extend .button;
  top: 0;
  right: 0;
  color: #6a6f71;
  background: #fff;

  &:hover {
    @extend .activeButton;
  }

  @include mq($until: tablet) {
    color: #fff;
    background: var(--color-scheme-accent-main);
  }
}

.activeButton {
  color: #fff;
  background: var(--color-scheme-accent-main);
}

.icon {
  font-size: 40px;
}

.suggestionsWrapper {
  position: absolute;
  z-index: 1;
  width: calc(100% - 1px);
}

.suggestions {
  padding: 0 30px 30px;
  background: #efefef;
  border-bottom-right-radius: 36px;
  border-bottom-left-radius: 36px;

  &::before {
    display: block;
    width: calc(100% - 100px);
    height: 1px;
    margin: 0 auto 25px;
    content: '';
    background-color: #d9d9d9;
  }
}

.showSuggestions {
  input {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
