@import '../../../../../../../styles/shared';

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;

  @include mq($from: desktop) {
    font-size: 20px;
  }
}
