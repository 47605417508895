@import '../../../../../../../styles/shared';

.wrapper {
  display: flex;
  align-items: center;
  gap: 13px;
  cursor: pointer;

  @include mq($from: desktop) {
    gap: 20px;
  }

  &:hover .link {
    border-bottom-color: #fff;
  }
}

.link {
  padding-bottom: 8px;
  font-size: 16px;
  border-bottom: 1px solid var(--color-scheme-accent-main);
  transition: border-bottom-color 0.4s;

  @include mq($from: desktop) {
    font-size: 18px;
    border-bottom-color: rgba(255, 255, 255, 0.38);
  }

  @include mq($from: wide) {
    font-size: 24px;
  }
}

.arrowButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  background: var(--color-scheme-accent-main);
  user-select: none;

  @include mq($from: wide) {
    width: 33px;
    height: 33px;
    min-width: 33px;
  }
}

.arrowIcon {
  font-size: 20px;
  color: #fff;

  @include mq($from: wide) {
    font-size: 22px;
  }
}
