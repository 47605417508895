@import '../../../../../../../styles/shared';

.image {
  width: 435px;
  border-radius: 40px;
  transform: perspective(1500px) rotateY(-15deg);
  transition: transform 1s ease 0s;

  &:hover {
    transform: perspective(3000px) rotateY(5deg);
  }
}
