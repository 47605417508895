@import '../../../../../../../styles/shared';

.logo {
  img {
    height: 30px;

    @include mq($from: desktop) {
      height: 50px;
    }
  }
}
